import React from 'react';
import PropTypes from 'prop-types';

import routes from '$routes';
import store from '$redux/store';
import { canSelector } from '$redux/login/selectors';

import MenuItem from './MenuItem';

const routeData = path => routes.find(r => r.path === path);

const renderMenuItem = (path, onClick, children, paths) => {
  const r = routeData(path);
  const state = store.getState();

  // check user capabilities
  const userCan = canSelector(state);
  if (r.permission && !userCan(r.permission)) return null;

  // user can see this
  return (
    <MenuItem
      onClick={onClick}
      to={path}
      hasSubPaths={r.hasSubPaths}
      isMenu={!!children}
      paths={paths}
    >
      {r.title}{children}
    </MenuItem>
  );
};

const Menu = ({ onClickItems }) => (
  <ul>
    {renderMenuItem('/', onClickItems)}
    {renderMenuItem('/matriculas', onClickItems, (
      <ul>
        {renderMenuItem('/matriculas-en-espera', onClickItems)}
        {renderMenuItem('/matriculas-transferencia-en-espera', onClickItems)}
        {renderMenuItem('/matriculas-recuperacion', onClickItems)}
      </ul>
    ), ['/matriculas', '/matriculas-en-espera', '/matriculas-transferencia-en-espera', '/matriculas-recuperacion'])}
    {renderMenuItem('/servicios', onClickItems, (
      <ul>
        {renderMenuItem('/alta-servicios', onClickItems)}
        {renderMenuItem('/consulta-servicios', onClickItems)}
        {renderMenuItem('/acciones-espera', onClickItems)}
      </ul>
    ), ['/servicios', '/alta-servicios', '/consulta-servicios', '/acciones-espera'])}
    {renderMenuItem('/alumnos', onClickItems)}
    {renderMenuItem('/pagos', onClickItems)}
    {renderMenuItem('/cobros', onClickItems)}
    {renderMenuItem('/facturacion', onClickItems)}
    {renderMenuItem('/descuentos', onClickItems)}
    {renderMenuItem('/codigos-promocionales', onClickItems)}

    {renderMenuItem('/utm-forms', onClickItems, (
      <ul>
        {renderMenuItem('/utms', onClickItems)}
        {renderMenuItem('/utm-form-ciclos', onClickItems)}
        {renderMenuItem('/candidates', onClickItems)}
        {renderMenuItem('/utm-comercial', onClickItems)}
        {renderMenuItem('/lead/exportar', onClickItems)}
      </ul>
    ), ['/utm-forms', '/utms', '/utm-form-ciclos', '/candidates', '/utm-comercial', '/lead/exportar'])}
    {renderMenuItem('/centros', onClickItems, (
      <ul>
        {renderMenuItem('/centros-colaboradores', onClickItems)}
      </ul>
    ), ['/centros', '/centros-colaboradores'])}
    {renderMenuItem('/ciclos', onClickItems, (
      <ul>
        {renderMenuItem('/ciclos-modalidades', onClickItems)}
        {renderMenuItem('/ciclos-categorias', onClickItems)}
        {renderMenuItem('/ciclos-codigos', onClickItems)}
        {renderMenuItem('/ciclos-precios', onClickItems)}
      </ul>
    ), ['/ciclos', '/ciclos-modalidades', '/ciclos-categorias', '/ciclos-codigos', '/ciclos-precios'])}
    {renderMenuItem('/calendario', onClickItems)}
    {renderMenuItem('/semestres', onClickItems)}
    {renderMenuItem('/configuracion-general', onClickItems)}
    {renderMenuItem('/usuarios', onClickItems)}
    {renderMenuItem('/configuracion-mails', onClickItems)}
  </ul>
);

Menu.propTypes = {
  onClickItems: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

Menu.defaultProps = {
  onClickItems: () => {
  },
  location: {
    pathname: '',
  },
};

export default Menu;
