import Home from './Home';
import Enrollments from './Enrollments';
import EnrollmentsEdit from './Enrollments/Single/Edit';
import EnrollmentsModify from './Enrollments/Single/Modify';
import EnrollmentHolds from './EnrollmentHolds';
import TransferenciaEspera from './Enrollments/TransferenciaEspera';
import ServiceRegister from './Services/ServiceRegister';
import EnrollmentsWithServices from './EnrollmentsWithServices';
import MethodologyChange from './Enrollments/Single/Modify/MethodologyChange';
import Students from './Students';
import Messages from './Messages';
import Charges from './Charges';
import ChargesEdit from './Charges/Single/Edit';
import ChargesNew from './Charges/Single/New';
import Billing from './Billing';
import Discounts from './Discounts';
import DiscountsEdit from './Discounts/Single/Edit';
import DiscountsNew from './Discounts/Single/New';
import Vouchers from './Vouchers';
import VouchersEdit from './Vouchers/Single/Edit';
import VouchersNew from './Vouchers/Single/New';
import Utms from './Utms';
import UtmForms from './UtmForms';
import UtmStudents from './UtmStudents';
import UtmFormCourses from './UtmFormCourses';
import Academies from './Academies';
import AcademiesEdit from './Academies/Single/Edit';
import AcademiesNew from './Academies/Single/New';
import Courses from './Courses';
import Calendar from './Calendar';
import ModuleSemester from './ModuleSemesters';
import ModuleCode from './ModuleCodes';
import ModulePrice from './ModulePrices';
import CollaboratingCenter from './CollaboratingCenters';
import Staff from './Staffs';
import Services from './Services';
import ServiceActions from './Services/ServiceActions';
import Candidates from './Candidates';
import CandidatePaymentLogs from './CandidatePaymentLogs';
import StudentsEdit from './Students/Edit';
import StudentsServicesEdit from './Students/Service';
import StudentsServicesGrades from './Students/Grades';
import Settings from './Settings';
import CourseModality from './CourseModality';
import CourseCategory from './CourseCategory';
import CoursesEdit from './Courses/Single/Edit';
import CoursesNew from './Courses/Single/New';
import MailsConfig from './Mails';
import SplitPayments from './SplitPayments';
import ServiceActionsWaiting from './Services/ServiceActionsWaiting';
import UtmComercial from './UtmComercial';
import LeadExportar from './LeadExportar';
import OrderRecovery from './OrderRecovery';

import {
  PERMISSION_SUMMARY,
  PERMISSION_ENROLLMENT,
  PERMISSION_SERVICIOS,
  PERMISSION_ENROLLMENT_MATRICULAS_ESPERA,
  PERMISSION_ENROLLMENT_TRANSFERENCIAS_EN_ESPERA,
  PERMISSION_STUDENT,
  PERMISSION_MESSAGE,
  PERMISSION_CHARGE,
  PERMISSION_BILLING,
  PERMISSION_DISCOUNT,
  PERMISSION_VOUCHER,
  PERMISSION_LEAD,
  PERMISSION_ACADEMY,
  PERMISSION_COURSE,
  PERMISSION_CALENDAR,
  PERMISSION_MODULESEMESTERS,
  PERMISSION_SETTINGS,
  PERMISSION_STAFF,
  PERMISSION_MAILS,
} from '../constants';

export default [
  {
    path: '/',
    title: 'Inicio',
    component: Home,
    permission: PERMISSION_SUMMARY,
  },
  {
    hasSubPaths: true,
    path: '/matriculas',
    title: 'Matrículas',
    component: Enrollments,
    permission: PERMISSION_ENROLLMENT,
  },
  {
    path: '/matriculas/editar/:idEnrollment',
    title: 'Editar Matrícula',
    component: EnrollmentsEdit,
    permission: PERMISSION_ENROLLMENT,
  },
  {
    path: '/matriculas/modificar/:idEnrollment',
    title: 'Modificar Matrícula',
    component: EnrollmentsModify,
    permission: PERMISSION_ENROLLMENT,
  },
  {
    path: '/matriculas/metodologia/:idEnrollment/:idTrainingPlan/:idRoadMap/:idAcademy',
    title: 'Modificar Metodología de Matrícula',
    component: MethodologyChange,
    permission: PERMISSION_ENROLLMENT,
  },
  {
    hasSubPaths: true,
    path: '/alta-servicios',
    title: 'Alta Servicios',
    component: ServiceRegister,
    permission: PERMISSION_SERVICIOS,
  },
  {
    hasSubPaths: true,
    path: '/consulta-servicios',
    title: 'Consulta Servicios',
    component: EnrollmentsWithServices,
    permission: PERMISSION_SERVICIOS,
  },
  {
    hasSubPaths: false,
    path: '/matriculas-en-espera',
    title: 'Matrículas (En Espera)',
    component: EnrollmentHolds,
    permission: PERMISSION_ENROLLMENT_MATRICULAS_ESPERA,
  },
  {
    hasSubPaths: false,
    path: '/matriculas-transferencia-en-espera',
    title: 'Transferencias en espera',
    component: TransferenciaEspera,
    permission: PERMISSION_ENROLLMENT_TRANSFERENCIAS_EN_ESPERA,
  },
  {
    path: '/matriculas-recuperacion',
    title: 'Recuperación de Matriculas',
    component: OrderRecovery,
    permission: PERMISSION_CHARGE,
  },
  {
    hasSubPaths: true,
    path: '/alumnos',
    title: 'Alumnos',
    component: Students,
    permission: PERMISSION_STUDENT,
  },
  {
    path: '/alumnos/editar/:idStudent',
    title: 'Editar Alumno',
    component: StudentsEdit,
    permission: PERMISSION_STUDENT,
  },
  {
    path: '/alumnos/services/:idStudent',
    title: 'Editar Servicios',
    component: StudentsServicesEdit,
    permission: PERMISSION_STUDENT,
  },
  {
    path: '/alumnos/utms/:idStudent',
    title: 'Ver UTMs',
    component: UtmStudents,
    permission: PERMISSION_STUDENT,
  },
  {
    path: '/alumnos/grades/:idStudent',
    title: 'Editar grades',
    component: StudentsServicesGrades,
    permission: PERMISSION_STUDENT,
  },
  {
    path: '/mensajes',
    title: 'Mensajes',
    component: Messages,
    permission: PERMISSION_MESSAGE,
  },
  {
    path: '/pagos',
    title: 'Registro de pagos',
    component: SplitPayments,
    permission: PERMISSION_CHARGE,
  },
  {
    hasSubPaths: true,
    path: '/cobros',
    title: 'Cobros',
    component: Charges,
    permission: PERMISSION_CHARGE,
  },
  {
    path: '/cobros/nuevo',
    component: ChargesNew,
    permission: PERMISSION_CHARGE,
  },
  {
    path: '/cobros/editar/:id',
    component: ChargesEdit,
    permission: PERMISSION_CHARGE,
  },
  {
    path: '/facturacion',
    title: 'Facturación',
    component: Billing,
    permission: PERMISSION_BILLING,
  },
  {
    hasSubPaths: true,
    path: '/descuentos',
    title: 'Descuentos',
    component: Discounts,
    permission: PERMISSION_DISCOUNT,
  },
  {
    path: '/descuentos/nuevo',
    component: DiscountsNew,
    permission: PERMISSION_DISCOUNT,
  },
  {
    path: '/descuentos/editar/:id',
    component: DiscountsEdit,
    permission: PERMISSION_DISCOUNT,
  },
  {
    hasSubPaths: true,
    path: '/codigos-promocionales',
    title: 'Cód. promocionales',
    component: Vouchers,
    permission: PERMISSION_VOUCHER,
  },
  {
    hasSubPaths: true,
    path: '/servicios',
    title: 'Servicios',
    component: Services,
    permission: PERMISSION_STUDENT,
  },
  {
    path: '/servicios/acciones/:idUserService',
    title: 'Service Actions',
    component: ServiceActions,
    permission: PERMISSION_STUDENT,
  },
  {
    path: '/codigos-promocionales/nuevo',
    component: VouchersNew,
    permission: PERMISSION_VOUCHER,
  },
  {
    path: '/codigos-promocionales/editar/:id',
    component: VouchersEdit,
    permission: PERMISSION_VOUCHER,
  },
  {
    hasSubPaths: true,
    path: '/utms',
    title: 'Utms',
    component: Utms,
    permission: PERMISSION_LEAD,
  },
  {
    hasSubPaths: true,
    path: '/utm-forms',
    title: 'Leads',
    component: UtmForms,
    permission: PERMISSION_LEAD,
  },
  {
    hasSubPaths: true,
    path: '/centros',
    title: 'Centros',
    component: Academies,
    permission: PERMISSION_ACADEMY,
  },
  {
    path: '/centros/nuevo',
    component: AcademiesNew,
    permission: PERMISSION_ACADEMY,
  },
  {
    path: '/centros/editar/:id',
    component: AcademiesEdit,
    permission: PERMISSION_ACADEMY,
  },
  {
    hasSubPaths: true,
    path: '/centros-colaboradores',
    title: 'Centros Colaboradores',
    component: CollaboratingCenter,
    permission: PERMISSION_ACADEMY,
  },
  {
    path: '/centros-colaboradores/nuevo',
    component: CollaboratingCenter,
    permission: PERMISSION_ACADEMY,
  },
  {
    path: '/centros-colaboradores/editar/:id',
    component: CollaboratingCenter,
    permission: PERMISSION_ACADEMY,
  },
  {
    hasSubPaths: true,
    path: '/utm-form-ciclos',
    title: 'Utm Ciclos',
    component: UtmFormCourses,
    permission: PERMISSION_ACADEMY,
  },
  {
    path: '/utm-form-ciclos/nuevo',
    component: UtmFormCourses,
    permission: PERMISSION_ACADEMY,
  },
  {
    path: '/utm-form-ciclos/editar/:id',
    component: UtmFormCourses,
    permission: PERMISSION_ACADEMY,
  },
  {
    path: '/utm-comercial',
    title: 'Crear Utm',
    component: UtmComercial,
    permission: PERMISSION_LEAD,
  },
  {
    path: '/lead/exportar',
    title: 'Exportar',
    component: LeadExportar,
    permission: PERMISSION_LEAD,
  },
  {
    hasSubPaths: true,
    path: '/ciclos-modalidades',
    title: 'Modalidades (Ciclos)',
    component: CourseModality,
    permission: PERMISSION_COURSE,
  },
  {
    hasSubPaths: true,
    path: '/ciclos-categorias',
    title: 'Categorías (Ciclos)',
    component: CourseCategory,
    permission: PERMISSION_COURSE,
  },
  {
    hasSubPaths: true,
    path: '/ciclos',
    title: 'Ciclos',
    component: Courses,
    permission: PERMISSION_COURSE,
  },
  {
    path: '/ciclos/editar/:id',
    component: CoursesEdit,
    permission: PERMISSION_COURSE,
  },
  {
    path: '/ciclos/nuevo',
    component: CoursesNew,
    permission: PERMISSION_COURSE,
  },
  {
    path: '/calendario',
    title: 'Calendario',
    component: Calendar,
    permission: PERMISSION_CALENDAR,
  },
  {
    path: '/semestres',
    title: 'Semestres',
    component: ModuleSemester,
    permission: PERMISSION_MODULESEMESTERS,
  },
  {
    path: '/ciclos-codigos',
    title: 'Ciclo (Códigos)',
    component: ModuleCode,
    permission: PERMISSION_COURSE,
  },
  {
    path: '/ciclos-precios',
    title: 'Ciclo (precios)',
    component: ModulePrice,
    permission: PERMISSION_COURSE,
  },
  {
    path: '/candidates',
    title: 'Candidatos',
    component: Candidates,
    permission: PERMISSION_LEAD,
  },
  {
    path: '/candidate-payment-logs',
    title: 'Candidatos Payment Logs',
    component: CandidatePaymentLogs,
    permission: PERMISSION_LEAD,
  },
  {
    hasSubPaths: true,
    path: '/configuracion-general',
    title: 'Configuración general',
    component: Settings,
    permission: PERMISSION_SETTINGS,
  },
  {
    path: '/usuarios',
    title: 'Usuarios',
    component: Staff,
    permission: PERMISSION_STAFF,
  },
  {
    path: '/configuracion-mails',
    title: 'Configuración mails',
    component: MailsConfig,
    permission: PERMISSION_MAILS,
  },
  {
    path: '/acciones-espera',
    title: 'Acciones en espera',
    component: ServiceActionsWaiting,
  },
];
